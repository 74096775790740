import React, { createContext, Dispatch, useEffect, useState } from 'react';

import { RouteComponentProps, Router } from '@reach/router';

import AuthenticatedRoute from '@components/Auth/AuthenticatedRoute';
import Icon from '@components/Icon';
import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';
import NotFound from '@components/Routing/NotFound';
import { Submenu } from '@components/Submenu';
import Title from '@components/Title';
import ClubDetails from '@presenters/web/pages/Clubs/Details';
import EditClubContact from '@presenters/web/pages/Clubs/Details/Contacts/EditContact';
import AddClubManagementVendor from '@presenters/web/pages/Clubs/Details/ManagementSystems/AddVendor';
import EditManagementSystems from '@presenters/web/pages/Clubs/Details/ManagementSystems/EditVendor';
import AddMeeting from '@presenters/web/pages/Clubs/Details/Meetings/AddMeeting';
import EditMeeting from '@presenters/web/pages/Clubs/Details/Meetings/EditMeeting';
import ClubFinance from '@presenters/web/pages/Clubs/Finance/ClubFinance';
import ClubGoals from '@presenters/web/pages/Clubs/Goals/ClubGoals';
import AssignMemberToRole from '@presenters/web/pages/Clubs/ManageClubOfficers/AssignMemberToRole';
import AssignRoleToMember from '@presenters/web/pages/Clubs/ManageClubOfficers/AssignRoleToMember';
import RemoveOfficer from '@presenters/web/pages/Clubs/ManageClubOfficers/RemoveOfficer';
import ReplaceMemberToRole from '@presenters/web/pages/Clubs/ManageClubOfficers/ReplaceMemberToRole';
import ReplaceRoleToMember from '@presenters/web/pages/Clubs/ManageClubOfficers/ReplaceRoleToMember';
import ClubMembers from '@presenters/web/pages/Clubs/Members';
import AddMember from '@presenters/web/pages/Clubs/MembershipManagement/AddMember/AddMember';
import ChangeMembership from '@presenters/web/pages/Clubs/MembershipManagement/ChangeMembership';
import EditMembership from '@presenters/web/pages/Clubs/MembershipManagement/EditMembership';
import TerminateMembership from '@presenters/web/pages/Clubs/MembershipManagement/TerminateMembership';
import ClubOfficers from '@presenters/web/pages/Clubs/Officers/ClubOfficers';
import ClubReports from '@presenters/web/pages/Clubs/Reports';
import ClubReportsReact from '@presenters/web/pages/Clubs/Reports/ClubReportsReact';
import LeadsLandingPage from '@presenters/web/pages/Leads/MMLPage';
import AddCandidate from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/AddCandidate';
import CandidateDetails from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/CandidateDetails/CandidateDetails';
import CandidateNonClubMember from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/CandidateDetails/CandidateNonClubMember';
import IdentifyClubCandidate from '@presenters/web/pages/Leads/MMLPage/IdentifyClubCandidate';

import { ClubLeadersContextType } from '@domain/clubs/types';

import {
  getClubIdFromPath,
  getClubSubMenuItems,
  isClubRotaract,
} from '@use-cases/clubs';
import { isClubRotaractSatellite } from '@use-cases/clubs/helpers/isClubRotaractSatellite';
import {
  isClubImmediatePastOrFutureOfficer,
  sortIndividualLeadershipAffiliationType,
} from '@use-cases/clubs/helpers/isImmediatePastOrFutureOfficer';

import { useUserAccount } from '@repositories/auth/hooks';
import { useFetchAdminAccessRights } from '@repositories/clubs';
import { useFetchDominoOrganizationId } from '@repositories/domino';

import {
  isEditLevel,
  isManagerLevelLead,
  isViewLevel,
} from '@utils/access-helpers';
import {
  FEATURE_DOMINO_CDM_PI1,
  FEATURE_LEADS_MML,
  FEATURE_SHAREPOINT,
  isEnabled,
} from '@utils/features';
import { getClubTypeName } from '@utils/getClubTypeName';
import { getOrganizationBase } from '@utils/getOrganizationBase';
import { isRotaryClub } from '@utils/isRotaryClub';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';
import { useFetchMemberLeadershipsHistory } from '@hooks/useFetchLeadershipHistory';

import { DIS } from '@typings/dis';
import { ClubAffiliation } from '@typings/graphql';
import { Enum, From, mapEnum } from '@typings/map-enum';

const defaultClubLeadersContext = [] as ClubLeadersContextType;

export const ClubLeadersContext = createContext<
  [
    ClubLeadersContextType,
    Dispatch<React.SetStateAction<ClubLeadersContextType>>
  ]
>([
  defaultClubLeadersContext,
  prevState => ({ ...prevState, defaultClubLeadersContext }),
]);

const Club: React.FC<RouteComponentProps & {
  pageContext: { languagePrefix: string };
}> = ({ pageContext: { languagePrefix }, location }) => {
  const { t } = useTranslation();

  const { user } = useAppConfig();
  const individualIdForFetch = (user?.isLoggedIn && user.individualId) || null;
  const { data: userData } = useUserAccount(individualIdForFetch);

  const clubId = getClubIdFromPath(location?.pathname);

  const isDominoIncrementOneFlag = isEnabled(FEATURE_DOMINO_CDM_PI1);

  const [
    fetchLeadershipHistory,
    { data: memberLeadershipInfo, loading: memberLeadershipLoading },
  ] = useFetchMemberLeadershipsHistory();

  const [
    fetchDominoOrganizationId,
    { data: dominoOrgData, loading: dominoOrgIdLoading },
  ] = useFetchDominoOrganizationId();

  const dominoId = dominoOrgData?.getDominoOrganizationId.dominoId;

  useEffect(() => {
    if (clubId && isDominoIncrementOneFlag) {
      fetchDominoOrganizationId({
        variables: {
          idType: 'DISID',
          value: clubId,
        },
      });
    }
  }, [clubId]);

  const { data, loading } = useFetchAdminAccessRights(clubId);

  useEffect(() => {
    if (user?.isLoggedIn && user?.individualId) {
      fetchLeadershipHistory({
        variables: {
          id: user.individualId,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    return () => sessionStorage.removeItem('search');
  }, [data]);

  // Set context values
  const [clubLeadersContext, setClubLeadersContext] = useState<
    ClubLeadersContextType
  >({
    ...defaultClubLeadersContext,
  });

  if (loading || memberLeadershipLoading || dominoOrgIdLoading) {
    return <Loading />;
  }

  const {
    clubName,
    clubType = '',
    organizationBase,
    operationsAccess,
    riClubId,
  } = data?.club || {};

  if (!operationsAccess?.clubId) {
    return <NotFound default />;
  }

  const hasPermissonsToFinancesGoalsReports = isViewLevel(
    operationsAccess.financesGoalsReports
  );

  const userClubAffiliation: ClubAffiliation = (userData?.getIndividual
    .clubAffiliations as ClubAffiliation[])?.filter(
    affiliation => affiliation.clubId === clubId
  )[0];

  const clubLeadershipAffiliation = sortIndividualLeadershipAffiliationType(
    memberLeadershipInfo,
    ['Rotary_Club', 'Rotaract_Club']
  );

  const clubSubMenu = getClubSubMenuItems(
    t,
    operationsAccess,
    clubType,
    clubId,
    userClubAffiliation,
    clubLeadershipAffiliation[0]?.endDate || ''
  );

  const isRotaractSatellite = isClubRotaractSatellite(clubType);
  const formattedType = mapEnum(
    From.DIS,
    Enum.ClubType,
    clubType as DIS.ClubType
  );
  const isRotaractClub = isClubRotaract(clubType);

  const isLeaderForCurrentClub = isManagerLevelLead(
    operationsAccess.memberships
  );

  const isLeader = operationsAccess.leaderships === 'Manager';

  const isRotaractClubType = isRotaractClub || isRotaractSatellite;
  const isSearchPageComeFrom = sessionStorage.getItem('search') || '';

  const isMembershipCandidatesFeature = isEnabled(FEATURE_LEADS_MML);

  const isAuthenticatedToLeadsDrupal = isClubImmediatePastOrFutureOfficer(
    clubLeadershipAffiliation[0]?.endDate || ''
  );

  return (
    <OneColumn className="mb-20">
      <Helmet
        titleTemplate={`${t('metadata.title.clubs', 'Clubs')}: %s | ${t(
          'metadata.title.default',
          'My Rotary'
        )}`}
      />
      <div>
        {window.history.length > 1 && isSearchPageComeFrom && (
          <div>
            <span
              onClick={() => window.history.back()}
              className="inline-flex items-center font-bold text-bright-blue-600 link-styles text-xs leading-xs-heading mb-8"
            >
              <Icon name="arrows/left" className="mr-2" size="12" />
              {t('back-link-text', 'Back')}
            </span>
          </div>
        )}

        <div className="mb-5 tablet:mb-15 desktop:mb-15">
          <Title>{clubName}</Title>
          <h3 className="text-lg text-black">
            {getClubTypeName(t, formattedType)}
            {organizationBase &&
              ` (${getOrganizationBase(t, organizationBase)})`}
          </h3>
        </div>
      </div>
      <ClubLeadersContext.Provider
        value={
          // eslint-disable-next-line react/jsx-no-constructed-context-values
          [clubLeadersContext, setClubLeadersContext]
        }
      >
        <Submenu items={clubSubMenu} location={location} />
        <Router basepath={`/${languagePrefix}club`}>
          <ClubDetails
            path="/:clubId/details"
            clubId={clubId}
            dominoId={dominoId}
          />

          {/* Hide all routes except Club Details for Rotaract and RotaractSatellite */}

          {!isRotaractSatellite && (
            <>
              <AuthenticatedRoute
                isRotaractClub={isRotaractClub}
                path="/:clubId/roster"
                Component={ClubMembers}
              />

              <AuthenticatedRoute
                path="/:clubId/add-member"
                isRotaractClub={isRotaractClub}
                clubName={clubName}
                Component={AddMember}
                riClubId={riClubId}
              />
            </>
          )}

          {(isRotaractClub || isRotaryClub(clubType)) &&
            isEditLevel(operationsAccess?.leaderships) && (
              <>
                <AuthenticatedRoute
                  path="/:clubId/officers"
                  clubType={clubType}
                  Component={ClubOfficers}
                />
                <AuthenticatedRoute
                  path="/:clubId/edit-meeting/:meetingIndex"
                  Component={EditMeeting}
                  dominoId={dominoId}
                />
                <AuthenticatedRoute
                  path="/:clubId/add-meeting"
                  Component={AddMeeting}
                  dominoId={dominoId}
                />
                <AuthenticatedRoute
                  path="/:clubId/terminate-membership/:membershipId"
                  Component={TerminateMembership}
                />
                <AuthenticatedRoute
                  path="/:clubId/edit-contact"
                  Component={EditClubContact}
                  dominoId={dominoId}
                />
                <AuthenticatedRoute
                  path="/:clubId/officers/:leadershipId/:termStartYear/remove"
                  Component={RemoveOfficer}
                />
                <AuthenticatedRoute
                  path="/:clubId/officers/assign-member/:roleId/:startDate/:endDate"
                  clubType={clubType}
                  Component={AssignMemberToRole}
                />
                <AuthenticatedRoute
                  path="/:clubId/edit-membership/:membershipId"
                  Component={EditMembership}
                />
                <AuthenticatedRoute
                  path="/:clubId/change-membership/:membershipId"
                  Component={ChangeMembership}
                />
                <AuthenticatedRoute
                  path="/:clubId/officers/assign-role/:individualId"
                  Component={AssignRoleToMember}
                />
                <AuthenticatedRoute
                  path="/:clubId/officers/replace-role/:individualId"
                  Component={ReplaceRoleToMember}
                />
                <AuthenticatedRoute
                  path="/:clubId/officers/replace-member/:individualId/:leadershipId/:roleId/replace/:startDate/:endDate"
                  Component={ReplaceMemberToRole}
                  clubType={clubType}
                />
                <AuthenticatedRoute
                  path="/:clubId/edit-management-systems"
                  Component={EditManagementSystems}
                />
                <AuthenticatedRoute
                  path="/:clubId/add-vendor"
                  Component={AddClubManagementVendor}
                />
              </>
            )}

          {hasPermissonsToFinancesGoalsReports && (
            <AuthenticatedRoute
              path="/:clubId/finances"
              orgType={clubType}
              isLeader={isLeader}
              Component={ClubFinance}
            />
          )}

          {isMembershipCandidatesFeature &&
            (isAuthenticatedToLeadsDrupal || isLeaderForCurrentClub) &&
            !isRotaractSatellite && (
              <>
                <AuthenticatedRoute
                  path="/:clubId/manage-membership"
                  type="Club"
                  isDistrict={false}
                  Component={LeadsLandingPage}
                />
                <AuthenticatedRoute
                  path="/:clubId/manage-membership/candidate-details"
                  clubId={clubId}
                  Component={CandidateDetails}
                />
                <AuthenticatedRoute
                  isRotaractClub={isRotaractClub}
                  path="/:clubId/manage-membership/convert-candidate/:individualId"
                  clubId={clubId}
                  Component={CandidateNonClubMember}
                  clubName={clubName}
                  riClubId={riClubId}
                />
                <AuthenticatedRoute
                  path="/:clubId/manage-membership/candidate-details/identify-candidate"
                  isRotaractClub={isRotaractClub}
                  clubId={clubId}
                  Component={IdentifyClubCandidate}
                />
                <AuthenticatedRoute
                  path="/:clubId/manage-membership/add-member"
                  isRotaractClub={isRotaractClub}
                  clubName={clubName}
                  Component={AddCandidate}
                />
              </>
            )}

          {hasPermissonsToFinancesGoalsReports && (
            <>
              <AuthenticatedRoute path="/:clubId/goals" Component={ClubGoals} />
              {!isRotaractClubType && (
                <AuthenticatedRoute
                  path="/:clubId/reports"
                  riClubId={riClubId}
                  Component={
                    isEnabled(FEATURE_SHAREPOINT)
                      ? ClubReportsReact
                      : ClubReports
                  }
                  adminAccessRightsData={{ data, loading }}
                />
              )}
            </>
          )}
          <NotFound default />
        </Router>
      </ClubLeadersContext.Provider>
    </OneColumn>
  );
};

export default Club;
